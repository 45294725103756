import axiosPost from "./axiosPost";

export const RNESArticuloIns = async (credentials, data) => {
    const body = {
        service: "RNESArticuloIns",
        params: {
            RNESRubroId: data?.rubro,
            RNESArticuloMaximo: data?.cant,
            RNESArticuloNombre: data?.nombre,
            RNESArticuloNombrePlural: data?.plural,
        },
    };

    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
