import { Routes } from "consts/Routes-App";
import moment from "moment";
export const logout = () => {
    sessionStorage.clear();
    window.location.assign(Routes.login.route);
};

export const transformNumber = (value) => {
    if (Number.isInteger(value)) {
        const result = new Intl.NumberFormat("es-ES").format(value) + ",00";
        if (result.length === 7)
            return `${result.substring(0, 1)}.${result.substring(1)}`;
        return result;
    }
    if (/\.\d$/.test(value.toString())) {
        const result = new Intl.NumberFormat("es-ES").format(value) + "0";
        if (result.length === 7)
            return `${result.substring(0, 1)}.${result.substring(1)}`;
        return result;
    }

    const result = new Intl.NumberFormat("es-ES").format(value);
    if (result.length === 7)
        return `${result.substring(0, 1)}.${result.substring(1)}`;
    return result;
};

export const formatNumber = (value) =>
    new Intl.NumberFormat("es-ES").format(value);

export const handleResponse = (
    response,
    message,
    functions,
    toArray = false
) => {
    const {
        setSnackbarInfo = () => {},
        setInRequest = () => {},
        setIsOpen = () => {},
        setSelected = () => {},
        updateList = () => {},
    } = functions;

    if (response) {
        if (response.status.code === 1) {
            setSnackbarInfo({
                message,
                severity: "success",
                open: true,
            });
            setInRequest(false);
            setTimeout(() => {
                setSnackbarInfo({
                    message: "",
                    severity: "success",
                    open: false,
                });
                setIsOpen(false);
                toArray
                    ? setSelected([])
                    : setSelected({ status: false, row: {} });
                updateList();
            }, 3000);
        } else {
            setSnackbarInfo({
                message: response.status.errmsg,
                severity: response.status.action === "W" ? "warning" : "error",
                open: true,
            });
            setInRequest(false);
            setTimeout(() => {
                setSnackbarInfo({
                    message: "",
                    severity: "success",
                    open: false,
                });
            }, 3000);
        }
    }
};

export const getGridProps = (columns, rows, row, setter, classes, loading) => {
    return {
        localeText: {
            columnMenuLabel: "Menu",
            columnMenuShowColumns: "Mostrar columnas",
            columnMenuFilter: "Filtrar",
            columnMenuHideColumn: "Esconder",
            columnMenuUnsort: "Desordenar",
            columnMenuSortAsc: "Ordenar por ASC",
            columnMenuSortDesc: "Ordenar por DESC",
        },
        columns,
        rows: loading ? [] : rows,
        onRowClick: (e) => validateRowSelected(e, row, setter),
        className: classes.root,
        autoPageSize: true,
        hideFooterSelectedRowCount: true,
        disableMultipleSelection: true,
        pagination: true,
        showCellRightBorder: false,
        rowHeight: 36,
        disableColumnMenu: true,
    };
};

export const validateRowSelected = (newSelection, row, setSelected) => {
    if (row && row.id === newSelection.id) {
        newSelection.api
            .getRowElement(newSelection.id)
            .classList.remove("Mui-selected");
        return setSelected({ status: false, row: {} });
    }
    newSelection.api
        .getRowElement(newSelection.id)
        .classList.add("Mui-selected");
    return setSelected({ status: true, row: newSelection.row });
};

export const transformDate = (str) => {
    if (str.length > 10) {
        return moment
            .utc(str, "YYYY-MM-DD HH:mm:ss")
            .format("DD/MM/YYYY HH:mm:ss");
    } else {
        return `${str.substring(8)}/${str.substring(5, 7)}/${str.substring(
            0,
            4
        )}`;
    }
};

export const generateColumns = (titles) => {
    return titles.map((title) => {
        const isRightAligned = /Cantidad/.test(title);
        if (typeof title === "object") {
            return {
                valueGetter: (params) => {
                    if (params.value === null || params.value === undefined)
                        return "";
                    if (title.format) {
                        switch (title.format) {
                            case "number":
                                return formatNumber(params.value);
                            case "date":
                                return transformDate(params.value);
                            /*case 'dateTime':
                                return formatNumber(params.value);*/
                            default:
                                return params.value;
                        }
                    }
                    return params.value;
                },
                renderCell: (params) => (
                    <div style={isRightAligned ? { textAlign: "rigth" } : {}}>
                        {params.value}
                    </div>
                ),
                ...title,
            };
        }

        return {
            field: title,
            headerName: title,
            valueGetter: (params) => {
                // console.log("value params", title === "Cantidad");
                if (params.value === null || params.value === undefined)
                    return "";
                if (
                    (/Fecha/.test(title) ||
                        title === "Valido desde" ||
                        title === "Último cambio") &&
                    title !== "Fecha y hora"
                )
                    return transformDate(params.value);
                if (/Maximo|Minimo|Piso|Numero|Entrada|Salida/.test(title))
                    return params.value;
                if (title === "DNI") return transformDNI(params.value);
                if (
                    /^\d(.*\d)?$/.test(params.value) &&
                    title !== "MAC" &&
                    title !== "Horario" &&
                    !/Fecha/.test(title) &&
                    title !== "Nombre" &&
                    title !== "Zona" &&
                    title !== "Acceso" &&
                    title !== "Identificación" &&
                    title !== "Firmantes internos" &&
                    title !== "Firmantes externos" &&
                    title !== "Cantidad"
                )
                    return transformNumber(params.value);
                return params.value;
            },
            sortComparator: (v1, v2, param1, param2) => {
                // if (title === "Fecha asignación") {
                //     console.log(param1, param1);
                //     return param1.value - param1.value;
                // }
                if (typeof v1 === "number") return param1.value - param2.value;
                if (
                    /\d{2}\/\d{2}\/\d{4}/.test(v1) &&
                    /\d{2}\/\d{2}\/\d{4}/.test(v2)
                ) {
                    console.log(
                        v2,
                        v2.slice(6, 10) +
                            v2.slice(3, 5) +
                            v2.slice(0, 2) +
                            v2.slice(10)
                    );
                    return (
                        parseInt(
                            v1.slice(6, 10) +
                                v1.slice(3, 5) +
                                v1.slice(0, 2) +
                                v1.slice(10)
                        ) -
                        parseInt(
                            v2.slice(6, 10) +
                                v2.slice(3, 5) +
                                v2.slice(0, 2) +
                                v2.slice(10)
                        )
                    );
                }

                let first = "";
                for (let i = 0; i < v1.length; i++) {
                    first +=
                        v1[i].toLowerCase().charCodeAt(0).toString(2) + " ";
                }
                let second = "";
                for (let i = 0; i < v2.length; i++) {
                    second +=
                        v2[i].toLowerCase().charCodeAt(0).toString(2) + " ";
                }
                return parseInt(first, 2) - parseInt(second, 2);
            },
            flex:
                (/Fecha/.test(title) &&
                    title !== "Fecha y hora" &&
                    title !== "Fecha " &&
                    title !== "Fecha Estado" &&
                    title !== "Fecha Creacion") ||
                title === "Sexo" ||
                title === "Cantidad" ||
                title === "Artículo"
                    ? 0
                    : 1,
            maxWidth: 800,
            width: /Fecha/.test(title)
                ? 160
                : title === "Sexo"
                ? 60
                : title === "Onboarding"
                ? 100
                : /Apellido y Nombre/.test(title)
                ? 150
                : title === "Cantidad"
                ? 80
                : title === "Latitud y Longitud"
                ? 150
                : title === "Artículo"
                ? 80
                : 0,
            renderCell: (params) => {
                if (title === "Cantidad") {
                    return (
                        <div
                            style={{
                                paddingLeft: "40px",
                            }}
                        >
                            {params.value}
                        </div>
                    );
                }
            },
        };
    });
};

export const retrieveData = async (payload, setRows, getData, setData) => {
    if (setRows) setRows([]);
    const data = await getData(payload);
    setData(data);
};

export const formatDNI = (value) => {
    if (!isNaN(value)) return formatNumber(value);
    return value;
    /*if (/[^0-9]/.test(value[value.length - 1]) && value !== "") return;
    const text = value.replace(/\./g, "");
    if (text.length < 4) setDNI(text);
    if (text.length === 4) setDNI(`${text[0]}.${text.slice(1)}`);
    if (text.length === 5) setDNI(`${text.slice(0, 2)}.${text.slice(2)}`);
    if (text.length === 6) setDNI(`${text.slice(0, 3)}.${text.slice(3)}`);
    if (text.length === 7)
        setDNI(`${text.slice(0, 1)}.${text.slice(1, 4)}.${text.slice(4)}`);
    if (text.length === 8)
        setDNI(`${text.slice(0, 2)}.${text.slice(2, 5)}.${text.slice(5)}`);*/
};

export const transformDNI = (text) => {
    text = text.toString();
    if (text.length === 5) return `${text.slice(0, 2)}.${text.slice(2)}`;
    if (text.length === 6) return `${text.slice(0, 3)}.${text.slice(3)}`;
    if (text.length === 7)
        return `${text.slice(0, 1)}.${text.slice(1, 4)}.${text.slice(4)}`;
    if (text.length === 8)
        return `${text.slice(0, 2)}.${text.slice(2, 5)}.${text.slice(5)}`;
    return text;
};

export const sessionStoragePersistRecoil =
    (key) =>
    ({ setSelf, onSet }) => {
        const savedValue = sessionStorage.getItem(key);
        if (savedValue != null) {
            setSelf(JSON.parse(savedValue));
        }

        onSet((newValue, _, isReset) => {
            isReset
                ? sessionStorage.removeItem(key)
                : sessionStorage.setItem(key, JSON.stringify(newValue));
        });
    };
