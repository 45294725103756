import axiosPost from "./axiosPost";

export const RNESArticulosGet = async (credentials, data) => {
    const body = {
        service: "RNESArticuloGet",
        params: {
            RNESRubroId: data?.rubroId || null,
            RNESArticuloId: data?.articuloId || null,
        },
    };

    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
