import { max } from "moment";

export const usuariosXServicio = [
    {
        field: "fullName",
        headerName: "Apellido y Nombre",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "document",
        headerName: "Documento",
        type: "number",
        format: "number",
        width: 120,
    },
    {
        field: "gender",
        headerName: "Género",
        width: 70,
        align: "center",
    },
    {
        field: "birthDay",
        headerName: "Nacimiento",
        type: "date",
        format: "date",
    },
    {
        field: "rol",
        headerName: "Rol",
        minWidth: 240,
    },
    {
        field: "onboard",
        headerName: "Onboard",
        align: "center",
    },
    {
        field: "creationDate",
        headerName: "Alta",
        type: "date",
        format: "date",
    },
    {
        field: "status",
        headerName: "Estado",
    },
    {
        field: "statusDate",
        headerName: "Fecha",
        type: "date",
        format: "date",
    },
];

export const rubrosTitles = [
    {
        field: "name",
        headerName: "Nombre",
        flex: 1,
        minWidth: 180,
    },
];

export const articulosTitles = [
    {
        field: "name",
        headerName: "Nombre del artículo",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "rubro",
        headerName: "Rubro",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "plural",
        headerName: "Plural del artículo",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "cant",
        headerName: "Cantidad máxima",
        flex: 1,
        minWidth: 180,
    },
];

export const asignacionTitles = [
    {
        field: "name",
        headerName: "Articulo",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "rubro",
        headerName: "Rubro",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "fechaEntrega",
        headerName: "Fecha disponible",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "cant",
        headerName: "Cantidad",
        minWidth: 50,
        maxWidth: 180,
        align: "right",
    },
    {
        field: "cantDisponible",
        headerName: "Cantidad entregada",
        minWidth: 50,
        maxWidth: 180,
        align: "right",
    },
    {
        field: "asignado",
        headerName: "Asignado por",
        minWidth: 340,
    },
    {
        field: "fechaAsignacion",
        headerName: "Fecha de asignación",
        flex: 1,
        minWidth: 180,
    },
];

export const movimientosBeneficiario = [
    "Fecha",
    "Cantidad",
    "Artículo",
    "Entregado por",
    "Identificador",
    "Latitud y Longitud",
];

export const movimientosDespachante = [
    "Fecha",
    "Cantidad",
    "Artículo",
    "Entregado a",
    "Identificador",
    "Latitud y Longitud",
];

export const resumenDisponibles = ["Fecha disponible", "Articulo", "Cantidad", "Asignado por", "Fecha asignación"];
export const resumenTransferencias = [
    "Fecha disponible",
    "Articulo",
    "Cantidad",
    "Transferido a ",
    "Fecha asignación"
];
export const resumenAsignaciones = [
    "Fecha disponible",
    "Articulo",
    "Cantidad",
    "Asignado por",
    "Fecha asignación"
];
export const resumenRetiro = [
    "Fecha entrega",
    "Articulo",
    "Cantidad",
    "Entregado por ",
    "Fecha disponible",
];
