import React, { useCallback, useEffect, useRef, useState } from "react";
import { CircularIndeterminate, FormButton } from "components";
import { FormikNumberInput, FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";
import styles from "styles/pages/ModalActions.module.scss";
import { handleResponse } from "services/Local-services";
import { RNConnectionIns } from "services/services-mia/RNConnectionIns";
import { RNESArticulosGet } from "services/services-mia/RNESArticulosGet";
import { RNESArticuloDel } from "services/services-mia/RNESArticuloDel";

export default function DeleteModal({ user, updateList, closeModal, setterRowSelected, filtro }) {
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState();
    const [rubros, setRubros] = useState(filtro);
    const formikRef = useRef();
    const getArticulosData = async () => {
        setIsLoading(true);
        const result = await RNESArticulosGet(credentials, { articuloId: user.id })
        console.log("result", result);
        formikRef.current.setValues({
            id: result[0].RNESArticuloId,
            nombre: result[0].RNESArticuloNombre,
            rubro: result[0].RNESRubroId,
            plural: result[0].RNESArticuloNombrePlural,
            cant: result[0].RNESArticuloMaximo,
            version: result[0].RNESArticuloVersion,
        });
        setData(result[0]);
        setIsLoading(false);
    }
    const handleDeleteArticulo = async (values) => {
        const response = await RNESArticuloDel(
            credentials,
            { id: data.RNESArticuloId }
        );
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Articulo eliminado correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            setterRowSelected({ status: false, row: {} })
            closeModal();
        }
    }

    useEffect(() => {
        getArticulosData()
    }, []);
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                nombre: "",
                rubro: "",
                plural: "",
                cant: ""
            }}
            validationSchema={
                yup.object({
                    nombre: yup.string("Debe ingresar un nombre").max(45, "Debe tener menos de 45 caracteres").required("Debe ingresar un nombre"),
                    rubro: yup.string("Debe seleccionar un rubro").required("Debe ingresar un rubro"),
                    plural: yup.string("Debe ingresar un plural").required("Debe ingresar un plural"),
                    cant: yup.number("Debe ingresar una cantidad").max(99, "Debe ser menor a 100").min(1, "Debe ser mayor a 0").required("Debe ingresar una cantidad"),
                })
            }
            onSubmit={(values) => {
                handleDeleteArticulo(values);
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    <>
                        <FormikSelectInput
                            fullWidth={true}
                            name="rubro"
                            labelText="Rubro"
                            onChange={(e) => {
                                formikRef.current.setFieldValue("rubro", e.target.value);
                            }}
                            disabled={true}
                        >
                            <option value="" selected disabled >Seleccione</option>
                            {rubros.map(({ RNESRubroId, RNESRubroNombre }) => (
                                <option
                                    key={RNESRubroId}
                                    data-id={RNESRubroId}
                                    value={RNESRubroId}
                                >
                                    {RNESRubroNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                        <FormikTextInput
                            fullWidth={true}
                            name="nombre"
                            placeholder=""
                            labelText="Nombre del artículo"
                            disabled={true}
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="plural"
                            placeholder=""
                            labelText="Plural del artículo"
                            disabled={true}
                        />
                        <FormikNumberInput
                            fullWidth={true}
                            name="cant"
                            placeholder=""
                            labelText="Cantidad máxima"
                            disabled={true}
                        />
                    </>
                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Eliminar" }}
                        onCloseModal={closeModal}
                    />
                    {(inRequest) && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    );
}
