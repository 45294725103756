import axiosPost from "./axiosPost";

export const RNConnectionIns = async (data, credentials) => {
    const {
        servicioHooliCod,
        rolCod,
        usuarioNroDeDocumentoX,
        usuarioSexo,
        usuarioMail,
        usuarioCelular,
        name,
        lastname,
    } = data;
    const body = {
        service: "RNConnectionIns",
        params: {
            servicioHooliCod,
            rolCod,
            usuarioCelular,
            usuarioNroDeDocumentoX,
            usuarioApellidos: lastname,
            usuarioNombres: name,
            usuarioMail,
            usuarioSexo,
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
