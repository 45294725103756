export const INSIDE_DRAWER = {
    admin: {
        service_name: "Administración",
        show_drawer: true,
        isOpen: false,
        route: "administracion?servicio=",
    },
    config: {
        service_name: "Configuración",
        show_drawer: true,
        isOpen: false,
        route: "configuracion?servicio=",
    },
    users: {
        service_name: "Usuarios",
        show_drawer: true,
        isOpen: false,
        route: "usuarios",
    },
};

export const Routes = {
    login: {
        route: "/login",
    },
    dashboard: {
        route: "/",
        wordKey: "Home",
        showMenuItem: true,
        needSuperAuth: false,
    },

    usuarios: {
        route: "/usuario",
        wordKey: "Administrativos",
        showMenuItem: true,
        needSuperAuth: false,
    },

    administracion: {
        route: "/administracion",
        wordKey: "Administracion de Usuarios",
        showMenuItem: false,
        needSuperAuth: false,
    },
    dashboardDatosPersonales: {
        route: "/administracion/dashboard-usuarios",
        wordKey: "Datos Personales",
        showMenuItem: false,
        needSuperAuth: false,
    },

    usuariosXServicio: {
        route: "/usuarios-x-servicio",
        wordKey: "Usuarios x servicio",
        showMenuItem: false,
        needSuperAuth: false,
    },
};

export const servicesRoutes = {
    RNCALOR: [
        {
            route: "/beneficiarios",
            wordKey: "Beneficiarios",
            isGroup: false,
        },
        {
            route: "/despachantes",
            wordKey: "Despachantes",
            isGroup: false,
        },

        {
            route: "/usuarios",
            wordKey: "Administrativos",
            isGroup: false,
        },
    ],
    RNES: [
        {
            route: "/rubros",
            wordKey: "Rubros",
            isGroup: false,
        },
        {
            route: "/articulos",
            wordKey: "Articulos",
            isGroup: false,
        },
        {
            route: "/beneficiarios",
            wordKey: "Beneficiarios",
            isGroup: false,
        },
        {
            route: "/despachantes",
            wordKey: "Despachantes",
            isGroup: false,
        },
        {
            route: "/usuarios",
            wordKey: "Administrativos",
            isGroup: false,
        },
        // {
        //     route: "/informes",
        //     wordKey: "Informes",
        //     isGroup: false,
        // },

    ],
};
